<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted' : {default: null}, 'pDisabled' : {default: null}},
  props: {
    label: {
      type: String,
      required: false
    },
    modelValue: {},
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4 col-6"
    },
    options: {
      type: Array,
      required: true
    },
    optionLabel: {
      type: [String, Function],
      required: false,
      default: "label"
    },
    optionValue: {
      type: [String, Function],
      required: false,
      default: "value"
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    optionDisabled: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    link: {
      type: Object,
      required: false,
      default: undefined
    },
    maxSelectedLabels: {
      type: Number,
      required: false,
      default: -1
    },
    displayer: {
      type: Function,
      required: false,
      default: undefined
    },
    filter: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {},
  computed: {
    currentValue: {
      get() {
        if (this.displayer) return this.displayer(this.modelValue);
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.validator !== undefined && this.validator.$invalid;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.validator !== undefined && this.validator.required !== undefined && this.validator.required.$invalid;
    },
  }
}
</script>

<template>
  <div :class="this.className" class="form-group">
    <AkLabel :required="isRequired">{{ this.label }}</AkLabel>
    <MultiSelect v-model="currentValue" :class="{'p-invalid':isInvalid && alreadySubmitted}" :disabled="isDisabled"
              :option-label="this.optionLabel" :editable=this.editable :option-disabled="this.optionDisabled"
              :option-value="this.optionValue" :options=this.options :loading=this.loading :filter=this.filter
              :placeholder="getPlaceholder" class="form-control" :maxSelectedLabels="this.maxSelectedLabels < 0 ? this.options.length : this.maxSelectedLabels"/>
    <RouterLink v-if="isDisabled && this.link !== undefined" :to="{name: this.link.route, params: this.link.params}">
      <i class="icon-link" :class="this.link.icon"/>
    </RouterLink>

    <small
        v-if="isValidatorRequired && alreadySubmitted"
        class="p-error">{{
        $t("field_required", [this.label])
      }}</small>

  </div>
</template>

<style>
.p-multiselect .p-multiselect-label {
  padding: 0.2rem 0rem;
}
</style>